import React from "react";
import { useRecoilState } from "recoil";

/* states */
import { errorContentDefault } from "../states/app_state";

export const NotFound = () => {


  console.log(text2)

	return (
		<div
			className='h-full w-full bg-slate-100
                    flex justify-center items-center

                    '
		>
			
		</div>
	);
};
